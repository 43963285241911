import React, { FunctionComponent, useState } from 'react';
import ReactDOM from 'react-dom';

import RadioButton, { RadiobuttonProps } from 'common/components/Radiobutton';

interface ExampleProps {
  radioButton1Props: RadiobuttonProps;
  radioButton2Props: RadiobuttonProps;
  radioButton3Props: RadiobuttonProps;
}

const OPTIONS = {
  ONE: 'Option One',
  TWO: 'Option Two',
  THREE: 'Option Three'
};

const Example: FunctionComponent<ExampleProps> = ({
  radioButton1Props,
  radioButton2Props,
  radioButton3Props,
  children
}) => {
  /* eslint-disable @typescript-eslint/indent */
  //extra spaces here look bad in the demo
  const code = `const radioButton1Props = ${JSON.stringify(radioButton1Props, null, 2)};\nconst radioButton2Props = ${JSON.stringify(radioButton2Props, null, 2)};\nconst radioButton3Props = ${JSON.stringify(radioButton3Props, null, 2)};
return (
  <RadioButton {...radioButton1Props} />
  <RadioButton {...radioButton2Props} />
  <RadioButton {...radioButton3Props} />
);`;
  /* eslint-enable @typescript-eslint/indent */

  return (
    <div className="styleguide-example">
      {children}
      <RadioButton {...radioButton1Props} />
      <RadioButton {...radioButton2Props} />
      <RadioButton {...radioButton3Props} />
      <pre className="styleguide-code-example">
        {code}
      </pre>
    </div>
  );
};

const RadioButtonDemo: FunctionComponent = () => {
  const [state, setState] = useState(OPTIONS.ONE);

  const example1Props = {
    checked: state === OPTIONS.ONE,
    disabled: false,
    id: 'radio-button-one',
    onChange: () => setState(OPTIONS.ONE),
    name: 'radio-button-example',
    label: OPTIONS.ONE
  } as RadiobuttonProps;

  const example2Props = {
    checked: state === OPTIONS.TWO,
    disabled: false,
    id: 'radio-button-two',
    onChange: () => setState(OPTIONS.TWO),
    name: 'radio-button-example',
    label: OPTIONS.TWO
  } as RadiobuttonProps;

  const example3Props = {
    checked: state === OPTIONS.THREE,
    disabled: false,
    id: 'radio-button-three',
    onChange: () => setState(OPTIONS.THREE),
    name: 'radio-button-example',
    label: OPTIONS.THREE
  } as RadiobuttonProps;

  return (<div>
    <Example
      radioButton1Props={example1Props}
      radioButton2Props={example2Props}
      radioButton3Props={example3Props}
    >
      <p>A radio button or option button is a graphical control element that allows the user to choose only one of a predefined set of mutually exclusive options. The singular property of a radio button makes it distinct from a checkbox, which allows more than one item to be selected and for the unselected state to be restored </p>

      <br />

    </Example>
  </div>);
};

window.onload = () => {
  ReactDOM.render(
    React.createElement(RadioButtonDemo),
    document.getElementById('radiobutton-demo')
  );
};
